/*!
 * Bootstrap v2.0.4
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
*/

.feedback-modal {
    position: fixed;
    overflow: auto;

    top: calc(25vh);
    max-height: calc(70vh);

    width: calc(80vw);
    margin-left: calc(10vw);
    margin-right: calc(10vw);

    z-index: 2050;

    font-size: 0.75rem;
    color: #333;
    background-color: #ffffff;
    border: 1px solid #999;
    border-radius: 6px;
}

.feedback-header {
    padding: 12px;
    border-bottom: 1px solid #eee;
}
.feedback-header h3 {
    float: left;

    margin: 0;
    font-size: 1.2rem;
}
.feedback-header .feedback-close {
    float: right;

    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: silver !important;
}
.feedback-header .feedback-close::after {
    content: '\00D7';
}
.feedback-header .feedback-close:hover {
    cursor: pointer;
    text-decoration: none;

    color: black !important;
}
.feedback-header .clear {
    clear: both;
}


.feedback-btn {
    display: inline-block;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    color: #333333;
    text-align: center;
    vertical-align: middle;
    background-color: #f5f5f5;
    background-repeat: repeat-x;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border: 1px solid #ccc;
    border-bottom-color: #bbb;
    border-radius: 4px;
    cursor: pointer;
}
.feedback-btn:hover,
.feedback-btn:active,
.feedback-btn.active,
.feedback-btn.disabled,
.feedback-btn[disabled] {
    background-color: #e6e6e6;
}
.feedback-btn:active, .feedback-btn.active {
    background-color: #cccccc \9;
}
.feedback-btn:hover {
    color: #333333;
    text-decoration: none;
    background-color: #e6e6e6;
    background-position: 0 -15px;
}
.feedback-btn:focus {
    outline: thin dotted #333;
    outline-offset: -2px;
}
.feedback-btn.active, .feedback-btn:active {
    background-image: none;
    background-color: #e6e6e6;
    background-color: #d9d9d9 \9;
    outline: 0;
}
.feedback-btn.disabled, .feedback-btn[disabled] {
    cursor: default;
    background-image: none;
    background-color: #e6e6e6;
    opacity: 0.65;
}

.feedback-btn-small {
    padding: 5px 9px;
    font-size: 11px;
    line-height: 16px;
}
.feedback-btn-small [class^="icon-"] {
    margin-top: -1px;
}

.feedback-btn .caret {
    margin-top: 7px;
    margin-left: 0;
}
.feedback-btn:hover .caret, .open.btn-group .caret {
    opacity: 1;
}

.feedback-btn-small .caret {
    margin-top: 4px;
}

.feedback-bottom-right {
    position:fixed;
    bottom:5px;
    right:5px;
}
.feedback-body {
    padding: 15px;
}
.feedback-body .modal-form {
    margin-bottom: 0;
}


.feedback-body textarea {
    vertical-align: middle;
    overflow: auto;
    vertical-align: top;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;

    display: inline-block;
    width: 100%;
    height: 5rem;
    padding: 4px;
    font-size: 13px;
    color: #555555;
    border: 1px solid #ccc;
}

.feedback-body textarea:focus {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    outline: thin dotted \9;
}



.feedback-footer {
    padding: 5px 14px 5px 15px;
    line-height:20px;
    margin-bottom: 0;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-radius: 0 0 6px 6px;
}
.feedback-footer:before, .feedback-footer:after {
    display: table;
    content: "";
}
.feedback-footer::after {
    clear: both;
}
.feedback-footer .feedback-btn {
    float: right;
    margin-left: 5px;
    margin-bottom: 0;
}


.feedback-glass {
    position:fixed;
    z-index:2000;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    background: #777;
    opacity: 0.5;
}

.feedback-canvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index:1999;
}

.feedback-highlight-element, .feedback-highlighted {
    position:absolute;
    width: 0;
    height: 0;

    border-radius: 4px;

    z-index: 2000;
}

.feedback-highlight-element {
    pointer-events:none;
}

#feedback-highlight-close {
    background: #ccc;
    border:1px solid black;
    text-align:center;
    line-height:20px;
    font-size:20px;
    font-weight:bold;
    width:20px;
    height:20px;
    left: -40px;
    position:absolute;
    cursor:pointer;
    margin-left:-12px;
    margin-top:-12px;

    z-index:2001;

    border-radius: 10px;

    background-color: #c5c5c5;
}

.feedback-animate-toside {
    position: fixed;
    top: calc(100vh - 200px);
    left: calc(100vw - 320px);
    width: 300px;
    margin: 0;
}

.feedback-review img {
    width: 100%;
}

.feedback-animate-review {
    top: calc(25vh);
    width: calc(80vw);
    margin: 0;
}

.feedback-body canvas {
    border-radius: 4px;
    border:1px solid #636363;
    position:absolute;
}

.feedback-loader {
    text-align: center;
}
.feedback-loader span {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin: 50px auto;
    background: black;
    border-radius: 50px;
    -webkit-animation: feedback-loader 0.9s infinite alternate;
    -moz-animation: feedback-loader 0.9s infinite alternate;
}
.feedback-loader span:nth-of-type(2) {
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
}
.feedback-loader span:nth-of-type(3) {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
}
@-webkit-keyframes feedback-loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
    -webkit-transform: translateY(0);
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0.1;
    -webkit-transform: translateY(-21px);
  }
}
@-moz-keyframes feedback-loader {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0.9;
    -moz-transform: translateY(0);
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0.1;
    -moz-transform: translateY(-21px);
  }
}
